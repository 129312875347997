/* eslint-disable @typescript-eslint/no-explicit-any */
import { computed, inject } from '@angular/core';
import { ConfigService } from '../config/config.service';
import { CerbosService } from './cerbos.service';

export abstract class CerbosPrincipalService {
	private readonly configService = inject(ConfigService);
	private readonly cerbosService = inject(CerbosService);

	protected readonly principal = computed(() => {
		const currentUser = this.configService.currentUser();

		return {
			id: currentUser?.username,
			role: currentUser?.role,
			attr: {
				userData: currentUser
			}
		};
	});

	// default resource
	abstract resource: any;

	readonly request = computed(() => ({
		principal: this.principal(),
		resource: this.resource()
	}));

	checkPermission(action: any, customAttrs?: any) {
		const resource = this.resource();
		const request = {
			principal: this.principal(),
			resource: {
				...resource,
				attr: {
					...resource.attr,
					...(customAttrs && { ...customAttrs })
				}
			}
		};
		const allowed = this.cerbosService.checkResource(request).isAllowed(action);

		// console.log(`[Permision check]: Action ${action} ${allowed ? 'allowed' : 'not allowed'}`, request);

		return allowed;
	}
}
